import { PictureSources } from "@components/solid/Image";
import type { Plug } from "@src-types/plug";
import { type Component } from "solid-js";
import styles from "./MultiHeroImage.module.css";

export const MultiHeroImage: Component<{ plug?: Plug }> = (props) => (
  <div class={styles["ctn"]}>
    <div class={styles["image-shadow"]} />
    <picture>
      <PictureSources images={props.plug?.backdropImage?.webImages} />
      <img
        class={styles["img"]}
        src={props.plug?.backdropImage?.webImages?.at(1)?.uri}
        alt={props.plug?.accessibilityLabel}
      />
    </picture>
  </div>
);
