import { psClient } from "@api/request";
import { session } from "@components/solid/AuthContext";
import { plugOutline } from "@components/solid/classLists";
import { PictureSources } from "@components/solid/Image";
import SnapSlider from "@components/solid/SnapSlider";
import type { LoginSession } from "@nrk/innlogging-web/redirect-login-client";
import type { components } from "@src-types/personlig-schema";
import {
  Index,
  Match,
  Show,
  Switch,
  createEffect,
  createResource,
  type Component,
} from "solid-js";

export type Response = components["schemas"]["PageableFavourites"];

const fetchUserFavoritesData = async (session: LoginSession) => {
  return await psClient.GET<Response>(
    `/radio/userdata/${session?.user?.sub}/favourites`,
    { credentials: true },
  );
};

/**
 * @depricated
 * My content shelf will be divded into two new shelfs.
 */
export const UserFavoritesShelf: Component = () => {
  const [data] = createResource(session, fetchUserFavoritesData);
  createEffect(() => {
    if (!data.loading) console.log(data());
  });

  return (
    <Show when={!data.loading}>
      <Switch>
        <Match when={data()}>
          <SnapSlider>
            <Index each={data()?.favourites}>
              {(fav, _) => (
                <li classList={plugOutline({ contract: "simple" })}>
                  <Switch>
                    <Match when={fav()._embedded.podcast}>
                      {(podcast) => (
                        <UserFavoritesPlug images={podcast().image} />
                      )}
                    </Match>
                    <Match when={fav()._embedded.programs}>
                      {(programs) => (
                        <UserFavoritesPlug images={programs().image} />
                      )}
                    </Match>
                    <Match when={fav()._embedded.series}>
                      {(series) => (
                        <UserFavoritesPlug images={series().image} />
                      )}
                    </Match>
                  </Switch>
                </li>
              )}
            </Index>
          </SnapSlider>
        </Match>
        <Match when={data.error}>
          <span>{data.error}</span>
        </Match>
      </Switch>
    </Show>
  );
};

const UserFavoritesPlug: Component<{
  images: Array<{ url: string; width: number }>;
}> = (props) => (
  <picture>
    <PictureSources images={props.images} />
    <img src={props.images.at(-1)?.url} />
  </picture>
);
