import { GET, psPagesClient } from "@api/request";
import { session } from "@components/solid/AuthContext";
import { button, typography } from "@components/solid/classLists";
import styles from "@components/solid/front-page/CrossDomainRecommendations.module.css";
import Icon from "@components/solid/Icon";
import { PictureSources } from "@components/solid/Image";
import SnapSlider from "@components/solid/SnapSlider";
import type { LoginSession } from "@nrk/innlogging-web/redirect-login-client";
import type { components } from "@src-types/personalised-pages-schema";
import {
  Index,
  Match,
  Show,
  Switch,
  createEffect,
  createResource,
  createSignal,
  splitProps,
  type Component,
} from "solid-js";
import type { JSX } from "solid-js/jsx-runtime";

type Response = components["schemas"]["RadioCrossDomainResponse"];

namespace CrossDomainPlug {
  export type Standalone = components["schemas"]["StandaloneCrossDomainPlug"];
  export type Series = components["schemas"]["SeriesCrossDomainPlug"];
  export type Episode = components["schemas"]["EpisodeCrossDomainPlug"];
  export type Article = components["schemas"]["ArticleCrossDomainPlug"];
  export type Any = Standalone | Series | Episode | Article;
}

export const CrossDomainRecommendations: Component<{
  pageId: string;
  position: number;
}> = (props) => {
  const fetchCrossDomainRecommendationsData = async (session: LoginSession) => {
    const [pageId, placeholderType] = [
      "discover",
      "crossDomainRecommendations",
    ];
    // https://personalised-pages-preprod.nrk.no/radio/crossdomain/v1/{pageRecommendationId}/{placeholderType}/{position}
    return await psPagesClient.GET<Response>(
      `/radio/crossdomain/v1/${pageId}/${placeholderType}/${props.position}`,
      {
        credentials: true,
        searchParams: {
          size: "large",
          ...(session.isLoggedIn && { userId: session.user?.sub }),
        },
      },
    );
  };

  const [resource] = createResource(
    session,
    fetchCrossDomainRecommendationsData,
  );

  createEffect(() => {
    if (!resource.loading) console.log(resource());
  });

  return (
    <Show when={!resource.loading}>
      <Switch>
        <Match when={resource()}>
          <SnapSlider>
            <Index each={resource()?.plugs}>
              {(plug, _) => <CrossDomainRecommendationPlug {...plug()} />}
            </Index>
          </SnapSlider>
        </Match>
        <Match when={resource.error}>
          <span>{resource.error}</span>
        </Match>
      </Switch>
    </Show>
  );
};

/**
 * TODO read this on a11y: https://inclusive-components.design/cards/
 * TODO follow up on Lium's recommandations: https://nrk.slack.com/archives/C051P2XEN8Y/p1721117710612539
 */

const CrossDomainRecommendationPlug: Component<CrossDomainPlug.Any> = (
  props,
) => {
  const [
    { fargerik, title, description, accessibilityLabel, image, labels },
    special,
  ] = splitProps(props, [
    "fargerik",
    "title",
    "description",
    "accessibilityLabel",
    "image",
    "labels",
  ]);
  const [isFavorite, setIsFavorite] = createSignal(special.isFavourite);

  const resolveTemplateUrl = (templateUrl?: string) => {
    const userSession = session();
    if (templateUrl && userSession?.user?.sub) {
      return templateUrl.replace("{userId}", userSession.user.sub);
    } else {
      console.error(`resolving template-url: ${templateUrl}`);
    }
  };

  const handleFavoriteClick: JSX.EventHandler<
    HTMLButtonElement,
    Event
  > = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const url = resolveTemplateUrl(
      isFavorite()
        ? special._links.deleteFavourite?.href
        : special._links.favourite.href,
    );
    if (url) {
      await GET(url, {
        credentials: true,
      }).then(
        () => setIsFavorite(!isFavorite()),
        // (error) => console.error(error),
      );
    }
  };

  return (
    <li
      role="listitem"
      style={{
        "background-color": fargerik.cardBackground,
      }}
      class={styles["item"]}
    >
      <a href={special._links.target.href} class={styles["href"]}>
        <picture class={styles["image"]}>
          <PictureSources images={image.webImages} />
          <img src={image.webImages.at(-1)?.uri} alt={accessibilityLabel} />
        </picture>
        <div class={styles["content"]}>
          <h3
            style={{ color: fargerik.title }}
            class={styles["title"]}
            classList={typography({ name: "Headline" })}
          >
            {title}
          </h3>
          <span
            style={{ color: fargerik.text }}
            classList={typography({ name: "Callout" })}
          >
            {description}
          </span>
          <div class={styles["bar"]}>
            <Index each={labels}>
              {(label, _) => (
                <div
                  style={{
                    "background-color": fargerik.labelBackground,
                    color: fargerik.labelText,
                  }}
                  class={styles["label"]}
                >
                  <span>{label()}</span>
                </div>
              )}
            </Index>
            <button
              style={{
                "background-color": fargerik.favoriteBackground,
                color: fargerik.icon,
              }}
              class={styles["fav-button"]}
              classList={button({
                type: "secondary",
                shape: "round",
                variant: "icon",
                size: "l",
              })}
              onClick={handleFavoriteClick}
            >
              <Icon iconID={isFavorite() ? "nrkStarActive" : "nrkStar"} />
            </button>
          </div>
        </div>
      </a>
    </li>
  );
};
