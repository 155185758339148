import { pagesClient } from "@api/request";
import { session } from "@components/solid/AuthContext";
import { CrossDomainRecommendations } from "@components/solid/front-page/CrossDomainRecommendations";
import MulitHero from "@components/solid/front-page/MultiHero/Index";
import { PersonalisedRecommandations } from "@components/solid/front-page/PersonalisedRecommandations";
import PlugResolver from "@components/solid/front-page/PlugResolver";
import PlugSlider from "@components/solid/front-page/PlugSlider";
import ShelfsSection from "@components/solid/front-page/ShelfsSection";
import { UserFavoritesShelf } from "@components/solid/front-page/UserFavoritesShelf";
import UserResumableListenings from "@components/solid/front-page/UserResumableListenings";
import type { LoginSession } from "@nrk/innlogging-web/redirect-login-client";
import type { components } from "@src-types/pages-schema";
import type { SupportedDisplayContract } from "@src-types/plug";
import type { JSX } from "astro/jsx-runtime";
import {
  Index,
  Match,
  Show,
  Switch,
  createResource,
  type ParentComponent,
} from "solid-js";

type IncludedSection = components["schemas"]["IncludedSection"];
type PlaceholderSection = components["schemas"]["PlaceholderSection"];
type PageOverviewResponse = components["schemas"]["Page"];

async function fetchPageOverviewData(session: LoginSession) {
  const [pageId] = ["discover"];
  return await pagesClient.GET<PageOverviewResponse>(`/v4/pages/${pageId}`, {
    credentials: session?.isLoggedIn ?? false,
    searchParams: {
      ...(session?.isLoggedIn && { userId: session?.user?.sub }),
    },
  });
}

type Props = {
  placeholder?: JSX.Element;
};

export const Shelfs: ParentComponent<Props> = (props) => {
  const [page] = createResource(session, fetchPageOverviewData);
  return (
    <Show when={page()}>
      {(page) => (
        <Index each={page().sections}>
          {(section, index) => (
            <Switch>
              <Match
                when={
                  section().type === "included" &&
                  (section() as IncludedSection)
                }
              >
                {(sec) => (
                  <Switch>
                    <Match
                      when={sec().included.displayContract === "radioMultiHero"}
                    >
                      <ShelfsSection type="subgrid">
                        <MulitHero section={sec()} />
                      </ShelfsSection>
                    </Match>
                    <Match when={sec().included.displayContract === "hero"}>
                      <ShelfsSection type="popout">
                        <PlugResolver
                          plugRef={
                            sec().included.plugRefs.map(({ ref }) => ref)[0]
                          }
                          type="hero"
                        ></PlugResolver>
                      </ShelfsSection>
                    </Match>
                    <Match
                      when={sec().included.displayContract !== "radioMultiHero"}
                    >
                      <ShelfsSection
                        title={sec().included.title}
                        type="breakout"
                      >
                        <PlugSlider
                          plugRefs={sec().included.plugRefs.map(
                            ({ ref }) => ref,
                          )}
                          displayContract={
                            sec().included
                              .displayContract as SupportedDisplayContract
                          }
                          size={sec().included.plugSize}
                        />
                      </ShelfsSection>
                    </Match>
                  </Switch>
                )}
              </Match>
              <Match
                when={
                  section().type === "placeholder" &&
                  (section() as PlaceholderSection)
                }
              >
                {(sec) => (
                  // TODO: (1) placeholder needs to be full breakout and (2) fully responsive
                  <Show
                    when={session()?.isLoggedIn}
                    fallback={
                      <ShelfsSection type="breakout">
                        {props.placeholder}
                      </ShelfsSection>
                    }
                  >
                    <Switch>
                      <Match when={sec().placeholder.type === "myContent"}>
                        <ShelfsSection type="breakout">
                          <UserFavoritesShelf />
                        </ShelfsSection>
                        <ShelfsSection
                          title="Fortsett der du slapp"
                          type="content"
                        >
                          <UserResumableListenings />
                        </ShelfsSection>
                      </Match>
                      <Match
                        when={
                          sec().placeholder.type ===
                          "personalisedRecommendations"
                        }
                      >
                        <ShelfsSection
                          title={sec().placeholder.title}
                          type="breakout"
                        >
                          <PersonalisedRecommandations
                            pageRecommendationId={page().recommendationId}
                            position={index}
                          />
                        </ShelfsSection>
                      </Match>
                      <Match
                        when={
                          sec().placeholder.type ===
                          "crossDomainRecommendations"
                        }
                      >
                        <ShelfsSection
                          title={sec().placeholder.title}
                          type="breakout"
                        >
                          <CrossDomainRecommendations
                            pageId={page().id}
                            position={index}
                          />
                        </ShelfsSection>
                      </Match>
                    </Switch>
                  </Show>
                )}
              </Match>
            </Switch>
          )}
        </Index>
      )}
    </Show>
  );
};
