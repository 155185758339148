import { typography } from "@components/solid/classLists";
import type { Plug } from "@src-types/plug";
import { type Component } from "solid-js";
import styles from "./MultiHeroContent.module.css";

export const MultiHeroContent: Component<{ plug?: Plug }> = (props) => {
  return (
    <div class={styles["ctn"]}>
      <span classList={typography({ name: "LargeTitle" })}>
        {props.plug?.title}
      </span>
      <span classList={typography({ name: "Body" })}>
        {props.plug?.description}
      </span>
    </div>
  );
};
