import { MultiHeroContent } from "@components/solid/front-page/MultiHero/MultiHeroContent";
import { MultiHeroImage } from "@components/solid/front-page/MultiHero/MultiHeroImage";
import { MultiHeroSlider } from "@components/solid/front-page/MultiHero/MultiHeroSlider";
import type { components } from "@src-types/pages-schema";
import type { Plug, PlugRef } from "@src-types/plug";
import { plugStore } from "@stores/plugStore";
import { createSignal, onMount, type Component } from "solid-js";
import styles from "./Index.module.css";

type Props = {
  section: components["schemas"]["IncludedSection"];
};

const MulitHero: Component<Props> = (props) => {
  const [activePlug, setActivePlug] = createSignal<PlugRef>();
  const onPlugClick = (ref: PlugRef) => setActivePlug(ref);
  onMount(() => setActivePlug(props.section.included.plugRefs[0].ref));
  const plug = (): Plug | undefined =>
    plugStore.plugs.find((p) => p.ref === activePlug())?.data;
  return (
    <>
      <div class={styles["img-wrapper"]}>
        <MultiHeroImage plug={plug()} />
      </div>
      <div class={styles["content-wrapper"]}>
        <MultiHeroContent plug={plug()} />
      </div>
      <div class={styles["slider-wrapper"]}>
        <MultiHeroSlider
          plugRefs={props.section.included.plugRefs.map(({ ref }) => ref)}
          onPlugClick={onPlugClick}
        />
      </div>
      {/* TODO: Push down slider dynamically */}
      <div class={styles["bottom-bar"]} />
    </>
  );
};

export default MulitHero;
