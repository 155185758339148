import { type ParentComponent } from "solid-js";
import type { PlugSize, SupportedDisplayContract } from "src/types/plug";
import styles from "./PlugPlaceholder.module.css";

type Props = {
  displayContract: SupportedDisplayContract;
  size?: PlugSize;
};

const PlugPlaceholder: ParentComponent<Props> = (props) => {
  return <div class={styles["plug-placeholder"]}>{props.children}</div>;
};

export default PlugPlaceholder;
