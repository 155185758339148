import type { Component } from "solid-js";
import "./ProgressMediaPlay.css";

type Props = {
  id?: string;
  progress: number;
  variant?: "live" | "overlay";
  size: "large" | "medium" | "small" | "tiny";
  playing?: boolean;
};

const ProgressMediaPlay: Component<Props> = (props) => {
  return (
    <>
      <progress-media-play
        aria-hidden="true"
        attr:data-size={props.size}
        attr:data-variant={props.variant}
        attr:data-progress={props.progress}
        attr:data-playing={props.playing}
      ></progress-media-play>
      <progress
        id={props.id}
        class="nrk-sr-only"
        max={100}
        value={props.progress}
      ></progress>
    </>
  );
};

export default ProgressMediaPlay;
