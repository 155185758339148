import { psClient } from "@api/request";
import type { LoginSession } from "@nrk/innlogging-web/redirect-login-client";
import {
  For,
  Match,
  Show,
  Switch,
  createEffect,
  createResource,
  splitProps,
  type Component,
  type ParentComponent,
} from "solid-js";

import { session } from "@components/solid/AuthContext";
import { button, typography } from "@components/solid/classLists";
import styles from "@components/solid/front-page/UserResumableListenings.module.css";
import Icon from "@components/solid/Icon";
import { PictureSources } from "@components/solid/Image";
import type { components } from "@src-types/personlig-schema";
import mockedResponse from "./continuations.json";

/**
 * https://psapi-preprod.nrk.no/documentation/redoc/personlig/#tag/Continuations/operation/ListContinuations
 */

namespace ContinuationsPlug {
  export type Episode = components["schemas"]["ContinuationEpisode"];
  export type SingleProgram =
    components["schemas"]["ContinuationSingleProgram"];
  export type Any = Episode | SingleProgram;
}

export type Response = components["schemas"]["Continuations"];

const fetchContinuationsData = async (session: LoginSession) => {
  return await psClient.GET<Response>(
    `/radio/userdata/${session.user?.sub}/continuations`,
    { credentials: true },
  );
};

const UserResumableListenings: Component<{}> = () => {
  const [resource, { mutate }] = createResource(
    session,
    fetchContinuationsData,
  );
  createEffect(() => {
    if (!resource.loading) {
      mutate(mockedResponse as Response);
    }
  });
  return (
    <Show when={!resource.loading}>
      <ul class={styles["list"]}>
        <Switch>
          <Match when={resource()}>
            <For each={resource()?.continuations}>
              {(resumeable, _) => (
                <Switch>
                  <Match
                    when={resumeable.type === "singleProgram" && resumeable}
                  >
                    {(singleProgram) => (
                      <ResumbleSingleProgramPlug {...singleProgram()} />
                    )}
                  </Match>
                  <Match when={resumeable.type === "episode" && resumeable}>
                    {(episode) => (
                      <>
                        <ResumbleEpisodePlug {...episode()} />
                        <ResumbleEpisodePlug {...episode()} />
                        <ResumbleEpisodePlug {...episode()} />
                      </>
                    )}
                  </Match>
                </Switch>
              )}
            </For>
          </Match>
        </Switch>
      </ul>
    </Show>
  );
};

const ResumbleEpisodePlug: Component<ContinuationsPlug.Episode> = (props) => {
  const [
    {
      _embedded: { catalogData },
      _links: links,
    },
    _,
  ] = splitProps(props, ["_embedded", "_links"]);
  return (
    <ResumablePlug {...props}>
      <h3
        class={styles["overflow-ellipsis"]}
        classList={typography({ name: "SubheadBold" })}
      >
        {catalogData.title.at(0)?.title}
      </h3>
      <span class={styles["overflow-ellipsis"]}>{catalogData.subtitle}</span>
    </ResumablePlug>
  );
};

const ResumbleSingleProgramPlug: Component<ContinuationsPlug.SingleProgram> = (
  props,
) => {
  const [
    {
      _embedded: { catalogData },
      _links: links,
    },
    _,
  ] = splitProps(props, ["_embedded", "_links"]);
  return (
    <ResumablePlug {...props}>
      <h3
        class={styles["overflow-ellipsis"]}
        classList={typography({ name: "SubheadBold" })}
      >
        {catalogData.title.at(0)?.title}
      </h3>
    </ResumablePlug>
  );
};

const ResumablePlug: ParentComponent<ContinuationsPlug.Any> = (props) => {
  const [
    {
      _embedded: { catalogData },
      _links: links,
    },
    _,
  ] = splitProps(props, ["_embedded", "_links"]);
  return (
    <li class={styles["card-item"]}>
      <picture>
        <PictureSources
          images={catalogData.image.map(({ url, width }) => ({
            uri: url,
            width,
          }))}
        />
        <img src={catalogData.image.at(-1)?.url} />
      </picture>
      <div class={styles["variant-wrapper"]}>{props.children}</div>
      <div class={styles["button-wrapper"]}>
        <button
          classList={button({
            type: "secondary",
            variant: "icon",
            shape: "round",
            size: "l",
          })} //css({ padding: "max(12px, 1vw)" })
        >
          <Icon iconID="nrkMediaPlay" />
        </button>
      </div>
    </li>
  );
};

export default UserResumableListenings;
