import { getEnv } from "@utils/getEnv";
import { isServer } from "solid-js/web";

export async function request<T>(
  url: string,
  config?: RequestInit,
): Promise<T> {
  const response = await fetch(url, config);
  return await response.json();
}

const getSession = async () =>
  (await import("@components/solid/AuthContext")).session();

// TODO: credentials should be authorization
type RequestOptions = {
  credentials: boolean;
  config?: RequestInit;
  searchParams?: Record<string, string>;
};

const authorizationHeader = async (): Promise<HeadersInit> => {
  const accessToken = (await getSession())?.accessToken;
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

// TODO: Should this be defaults?
const defaultConfig: RequestInit = {
  mode: "cors",
};

const resolveConfig = async (
  options: RequestOptions,
): Promise<RequestInit> => ({
  ...defaultConfig,
  ...options.config,
  headers: {
    ...options.config?.headers,
    ...(options.credentials ? await authorizationHeader() : undefined),
  },
});

const resolveUrl = async (
  baseUrl: URL,
  searchParams?: Record<string, string>,
): Promise<string> => {
  const session = await getSession();
  if (searchParams?.["userId"] && session?.isLoggedIn) {
    searchParams["userId"] = session?.user?.sub ?? "";
  }
  return Object.assign(baseUrl, {
    search: new URLSearchParams(searchParams).toString(),
  }).toString();
};

const ApiClient = (baseUrl: string) => ({
  GET: <T>(path: string, options?: RequestOptions) =>
    GET<T>(`${baseUrl}${path}`, options),
  POST: <T, TBody extends BodyInit>(path: string, body: TBody) =>
    POST<TBody, T>(`${baseUrl}${path}`, body),
});

export const GET = async <T>(path: string, options?: RequestOptions) =>
    request<T>(
    await resolveUrl(new URL(path), options?.searchParams),
      options ? await resolveConfig(options) : undefined,
  );

export const POST = <TBody extends BodyInit, T>(url: string, body: TBody) =>
  request<T>(url, { method: "POST", body });

/**
 * Pages (V4 - Atomic API)
 * https://pages-preprod.radio.api.nrk.no
 */

export const pagesClient = ApiClient(
  isServer
    ? process.env.PUBLIC_PS_API_PAGES_URL
    : getEnv().PUBLIC_PS_API_PAGES_URL,
);

/**
 * PS API
 */
export const psClient = ApiClient(
  isServer
    ? process.env.PUBLIC_PS_API_BASE_URL
    : getEnv().PUBLIC_PS_API_BASE_URL,
);

/**
 * PS Personalised Pages (v1) - Personalised content for front page
 * https://personalised-pages.nrk.no/
 */
export const psPagesClient = ApiClient(
  isServer
    ? process.env.PUBLIC_PERSONALISED_PAGES_BASE_URL
    : getEnv().PUBLIC_PERSONALISED_PAGES_BASE_URL,
);
