import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

type Duration = [hours: string, minutes: string];

const merge = (tuple: Duration) => tuple.join(" ");

const ifThen = (conditon: boolean, value: string) => (conditon ? value : "");

const t = (hours: string) => `${hours} t`;

const mm = (minutes: string) => `${minutes} min`;

type ISO8601Duration = string;
export const formatDuration = (duration: ISO8601Duration) => {
  const d = dayjs.duration(duration);
  const hours = d.format("H");
  const minutes = d.format("m");
  return merge([
    ifThen(d.hours() > 0, t(hours)),
    ifThen(d.minutes() > 0, mm(minutes)),
  ]);
};
