import { pagesClient } from "@api/request";
import { Hero } from "@components/solid/front-page/Hero/Hero";
import {
  Editorial,
  Landscape,
  LandscapeLogo,
  Simple,
  Squared,
  SquaredLogo,
  SquaredMultiHero,
  type PlugProps,
} from "@components/solid/front-page/Plugs";
import type {
  Plug,
  PlugRef,
  PlugSize,
  SupportedDisplayContract,
} from "@src-types/plug";
import { setPlugStore } from "@stores/plugStore";
import {
  Match,
  Show,
  Suspense,
  Switch,
  createEffect,
  createResource,
  type Component,
} from "solid-js";
import { Dynamic } from "solid-js/web";

type PlugOptions = Record<SupportedDisplayContract, Component<PlugProps>>;

const options: PlugOptions = {
  hero: Hero,
  simple: Simple,
  squared: Squared,
  squaredLogo: SquaredLogo,
  squaredMultiHero: SquaredMultiHero,
  landscape: Landscape,
  landscapeLogo: LandscapeLogo,
  editorial: Editorial,
};

const fetchPlugData = async (ref: PlugRef): Promise<Plug> =>
  pagesClient.GET<Plug>(`/v4/plugs/${ref}`, { credentials: true });

export const PlugResolver: Component<{
  plugRef: string;
  type: SupportedDisplayContract;
  size?: PlugSize;
}> = (props) => {
  const [plug] = createResource(props.plugRef, fetchPlugData);

  createEffect(() => {
    if (plug()) {
      setPlugStore("plugs", (plugs) => [
        ...plugs,
        { ref: props.plugRef, data: plug() },
      ]);
    }
  });

  return (
    <Suspense>
      <Show when={!plug.loading}>
        <Switch>
          {/* <Match when={plug.loading}>
              <PlugPlaceholder css={placeholderStyleOptions[props.type]} />
            </Match> */}
          <Match when={plug.error}>
            <span>Error: {plug.error}</span>
          </Match>
          <Match when={plug()}>
            {(plug) => (
              <Dynamic
                component={options[props.type]}
                plug={plug()}
                size={props.size}
              />
            )}
          </Match>
        </Switch>
      </Show>
    </Suspense>
  );
};

export default PlugResolver;
