import { typography } from "@components/solid/classLists";
import { Show, type ParentComponent } from "solid-js";
import styles from "./ShelfsSection.module.css";

const ShelfsSection: ParentComponent<{
  title?: string;
  type: "subgrid" | "content" | "breakout" | "popout";
}> = (props) => {
  return (
    <section class={styles[props.type]}>
      <Show when={props.title}>
        <h2
          class={styles["heading"]}
          classList={typography({ name: "HeadlineExtraBold" })}
        >
          {props.title}
        </h2>
      </Show>
      {props.children}
    </section>
  );
};

export default ShelfsSection;
