import { psPagesClient } from "@api/request";
import { session } from "@components/solid/AuthContext";
import { PictureSources } from "@components/solid/Image";
import SnapSlider from "@components/solid/SnapSlider";
import { plugOutline } from "@components/solid/classLists";
import type { LoginSession } from "@nrk/innlogging-web/redirect-login-client";
import type { components } from "@src-types/personalised-pages-schema";
import {
  Index,
  Match,
  Show,
  Switch,
  createEffect,
  createResource,
  splitProps,
  type Component,
} from "solid-js";

type Response = components["schemas"]["RadioPersonalisedPlugsResponse"];

namespace PersonalisedRecommandationPlug {
  export type Series = components["schemas"]["SeriesPlug"];
  export type Podcast = components["schemas"]["PodcastPlug"];
  export type PodcastSeason = components["schemas"]["PodcastSeasonPlug"];
  export type Any = Series | Podcast | PodcastSeason;
}

export const PersonalisedRecommandations: Component<{
  pageRecommendationId?: string;
  position: number;
}> = (props) => {
  const fetchPersonalisedRecommandationsData = async (
    session: LoginSession,
  ) => {
    const [placeholderType] = ["personalisedRecommendations"];
    // https://personalised-pages-preprod.nrk.no/radio/personalised/v2/{pageRecommendationId}/{placeholderType}/{position}
    return await psPagesClient.GET<Response>(
      `/radio/personalised/v2/${props.pageRecommendationId}/${placeholderType}/${props.position}`,
      {
        credentials: true,
        searchParams: {
          size: "large",
          ...(session.isLoggedIn && { userId: session.user?.sub }),
        },
      },
    );
  };

  const [data] = createResource(session, fetchPersonalisedRecommandationsData);

  createEffect(() => {
    if (!data.loading) console.log(data());
  });

  return (
    <Show when={!data.loading}>
      <Switch>
        <Match when={data()}>
          <SnapSlider>
            <Index each={data()?.plugs}>
              {(plug, _) => <PersonalisedRecommandationPlug {...plug()} />}
            </Index>
          </SnapSlider>
        </Match>
        <Match when={data.error}>
          <span>{data.error}</span>
        </Match>
      </Switch>
    </Show>
  );
};

const PersonalisedRecommandationPlug: Component<
  PersonalisedRecommandationPlug.Any
> = (props) => {
  const [{ image, accessibilityLabel }, _] = splitProps(props, [
    "image",
    "accessibilityLabel",
  ]);
  return (
    <li
      classList={plugOutline({ contract: "squared", size: "medium" })}
      tabIndex={0}
      aria-label={accessibilityLabel}
    >
      <picture>
        <PictureSources images={image.webImages} />
        <img src={image.webImages.at(-1)?.uri} alt={accessibilityLabel} />
      </picture>
    </li>
  );
};
