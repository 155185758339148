import { button, typography } from "@components/solid/classLists";
import ProgressMediaPlay from "@components/solid/common/ProgressMediaPlay";
import type { PlugProps } from "@components/solid/front-page/Plugs";
import Icon from "@components/solid/Icon";
import { PictureSources } from "@components/solid/Image";
import type { Plug } from "@src-types/plug";
import { formatDuration } from "@utils/date";
import { Match, splitProps, Switch, type Component } from "solid-js";
import { Dynamic } from "solid-js/web";
import styles from "./Hero.module.css";

export const Hero: Component<PlugProps> = (props) => {
  const [{ id, type, image, accessibilityLabel }, _] = splitProps(props.plug, [
    "id",
    "type",
    "image",
    "accessibilityLabel",
  ]);
  return (
    <div class={styles["wrp"]} data-type={type} data-id={id}>
      <div class={styles["img-ctr"]}>
        {/* TODO: Add live symbol with text for channel variant */}
        <picture>
          <PictureSources images={image.webImages} />
          <img
            class={styles["img"]}
            src={image.webImages.at(-1)?.uri}
            alt={accessibilityLabel}
          />
        </picture>
      </div>
      <div class={styles["ctn"]}>
        <div class={styles["txt-ctn"]}>
          <Dynamic component={promotion[props.plug.type]} {...props.plug} />
        </div>
        <div class={styles["action-ctr"]}>
          <Dynamic component={actionBar[props.plug.type]} {...props.plug} />
          <button
            class={styles["more-button"]}
            classList={button({
              type: "primary",
              variant: "icon",
              shape: "round",
              size: "s",
            })}
          >
            <Icon iconID="nrkMore" />
          </button>
        </div>
      </div>
    </div>
  );
};

type PlugComponentVariants = {
  episode: Component<Extract<Plug, { type: "episode" }>>;
  podcastEpisode: Component<Extract<Plug, { type: "podcastEpisode" }>>;
  podcastSeason: Component<Extract<Plug, { type: "podcastSeason" }>>;
  series: Component<Extract<Plug, { type: "series" }>>;
  standaloneProgram: Component<Extract<Plug, { type: "standaloneProgram" }>>;
  channel: Component<Extract<Plug, { type: "channel" }>>;
  podcast: Component<Extract<Plug, { type: "podcast" }>>;
  link: Component<Extract<Plug, { type: "link" }>>;
  page: Component<Extract<Plug, { type: "page" }>>;
};

const actionBar: PlugComponentVariants = {
  episode: (props) => {
    return (
      <>
        <button>
          <ProgressMediaPlay progress={0} size="large" />
          <div class={styles["info-play"]}>
            <span classList={typography({ name: "SubheadBold" })}>
              Hør serien
            </span>
            <span classList={typography({ name: "Footnote" })}>
              {formatDuration(props.episode.duration)}
            </span>
          </div>
        </button>
      </>
    );
  },
  podcastSeason: (props) => {
    return (
      <>
        <button>
          <ProgressMediaPlay progress={0} size="large" />
        </button>
        <div class={styles["info-play"]}>
          <span classList={typography({ name: "SubheadBold" })}>
            Hør serien
          </span>
          <span classList={typography({ name: "Footnote" })}>
            {props.podcastSeason.numberOfEpisodes} episoder
          </span>
        </div>
      </>
    );
  },
  series: (props) => {
    return (
      <>
        <button>
          <ProgressMediaPlay progress={0} size="large" />
        </button>
        <div class={styles["info-play"]}>
          <span classList={typography({ name: "SubheadBold" })}>
            Hør serien
          </span>
        </div>
      </>
    );
  },
  standaloneProgram: () => {
    return (
      <>
        <button>
          <ProgressMediaPlay progress={0} size="large" />
        </button>
      </>
    );
  },
  channel: (props) => {
    return (
      <>
        <button>
          <ProgressMediaPlay progress={0} size="large" variant="live" />
        </button>
        <div class={styles["info-play"]}>
          <span classList={typography({ name: "SubheadBold" })}>
            Hør direkte
          </span>
          <span></span>
        </div>
        <button
          classList={button({
            type: "primary",
            variant: "icon",
            shape: "round",
            size: "s",
          })}
        >
          {/* TODO: Create collapsing inline-icon to icon button */}
          <Icon iconID="nrkReload" />
        </button>
      </>
    );
  },
  podcast: (props) => {
    return (
      <>
        <button>
          <ProgressMediaPlay progress={0} size="large" />
        </button>
        <div class={styles["info-play"]}>
          <span classList={typography({ name: "SubheadBold" })}>
            Hør podcasten
          </span>
          <span classList={typography({ name: "Footnote" })}>
            {props.podcast.numberOfEpisodes} episoder
          </span>
        </div>
      </>
    );
  },
  podcastEpisode: (props) => {
    return (
      <>
        <button>
          <ProgressMediaPlay progress={0} size="large" />
        </button>
        <div class={styles["info-play"]}>
          <span classList={typography({ name: "SubheadBold" })}>
            Hør episoden
          </span>
          <span classList={typography({ name: "Footnote" })}>
            {formatDuration(props.podcastEpisode.duration)}
          </span>
        </div>
      </>
    );
  },
  link: () => {
    return (
      <>
        <button
          classList={button({ type: "secondary", variant: "icon", size: "l" })}
        >
          <Icon iconID="nrkOpenInNew" />
        </button>
        <div class={styles["info-play"]}>
          <span classList={typography({ name: "SubheadBold" })}>
            Åpne lenke
          </span>
        </div>
      </>
    );
  },
  page: () => {
    return (
      <>
        <button
          classList={button({ type: "secondary", variant: "icon", size: "l" })}
        >
          <Icon iconID="nrkCategory" />
        </button>
        <div class={styles["info-play"]}>
          <span classList={typography({ name: "SubheadBold" })}>
            Gå til kategori
          </span>
        </div>
      </>
    );
  },
};

const promotion: PlugComponentVariants = {
  episode: (props) => <PromotionText {...props} />,
  podcastSeason: (props) => <PromotionText {...props} />,
  series: (props) => <PromotionText {...props} />,
  standaloneProgram: (props) => <PromotionText {...props} />,
  channel: (props) => <PromotionText {...props} />,
  podcast: (props) => <PromotionText {...props} />,
  podcastEpisode: (props) => <PromotionText {...props} />,
  link: (props) => <PromotionText {...props} />,
  page: (props) => <PromotionText {...props} />,
};

const PromotionText: Component<
  Pick<Plug, "title" | "description" | "tagline">
> = (props) => (
  <Switch
    fallback={
      <span
        class={styles["headline-regular"]}
        classList={typography({ name: "Title1Regular" })}
      >
        {props.title}
      </span>
    }
  >
    <Match when={props.tagline}>
      <h3
        class={styles["headline"]}
        classList={typography({ name: "Title1Bold" })}
      >
        {props.tagline}
      </h3>
      <span class={styles["txt"]} classList={typography({ name: "Preamble" })}>
        {props.title}
      </span>
    </Match>
  </Switch>
);
