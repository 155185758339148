import { typography } from "@components/solid/classLists";
import styles from "@components/solid/front-page/Plugs.module.css";
import { PictureSources } from "@components/solid/Image";
import type { Plug, PlugSize } from "@src-types/plug";
import { type Component, type ParentComponent } from "solid-js";

export type PlugProps = {
  plug: Plug;
  size?: PlugSize;
};

export const SquaredMultiHero: Component<PlugProps> = (props) => {
  return (
    <>
      <picture>
        <PictureSources images={props.plug.image.webImages} />
        <img
          src={props.plug.image.webImages.at(-1)?.uri}
          alt={props.plug.accessibilityLabel}
        />
      </picture>
    </>
  );
};

/**
 * Squared
 */

export const SquaredLogo: ParentComponent<PlugProps> = (props) => {
  return (
    <>
      <picture>
        <PictureSources images={props.plug.image.webImages} />
        <img
          src={props.plug.image.webImages.at(-1)?.uri}
          alt={props.plug.accessibilityLabel}
        />
      </picture>
      {props.children}
    </>
  );
};

export const Squared: Component<PlugProps> = (props) => (
  <SquaredLogo {...props}>
    {/* <Title text={props.plug?.title} /> */}
  </SquaredLogo>
);

export const SquaredLogoPlay: Component<PlugProps> = (props) => (
  <SquaredLogo {...props}>
    <button>Play</button>
  </SquaredLogo>
);

/**
 * Editorial
 */

export const Editorial: Component<PlugProps> = (props) => {
  return (
    <>
      <picture>
        <PictureSources images={props.plug.image.webImages} />
        <img
          src={props.plug.image.webImages.at(-1)?.uri}
          alt={props.plug.accessibilityLabel}
        />
      </picture>
      {/* <Title text={props.plug?.title} /> */}
      {/* <Description text={props.plug?.description} /> */}
    </>
  );
};

export const Simple: Component<PlugProps> = (props) => {
  return (
    <>
      <picture>
        <PictureSources images={props.plug.image.webImages} />
        <img
          src={props.plug.image.webImages.at(-1)?.uri}
          alt={props.plug.accessibilityLabel}
        />
      </picture>
      {/* <Title text={props.plug?.title} /> */}
      {/* <Description text={props.plug?.description} /> */}
    </>
  );
};

/**
 * Landscape
 */

export const LandscapeLogo: ParentComponent<PlugProps> = (props) => {
  return (
    <>
      <picture>
        <PictureSources images={props.plug.image.webImages} />
        <img
          src={props.plug.image.webImages.at(-1)?.uri}
          alt={props.plug.accessibilityLabel}
        />
      </picture>
      {props.children}
    </>
  );
};
export const Landscape: Component<PlugProps> = (props) => (
  <LandscapeLogo {...props}>
    <Title text={props.plug?.title} />
  </LandscapeLogo>
);

/**
 * Consitituents
 */

export const Title: Component<{ text?: string }> = (props) => (
  <span class={styles["title"]} classList={typography({ name: "SubheadBold" })}>
    {props.text}
  </span>
);

export const Description: Component<{ text?: string }> = (props) => (
  <span
    class={styles["description"]}
    classList={typography({ name: "Footnote" })}
  >
    {props.text}
  </span>
);

// const Labels: Component<{ category: string; type: string }> = (props) => (
//   <div>
//     <span>{props.category}</span>
//     <span>{props.type}</span>
//   </div>
// );

/**
 * ActionBar
 * - dynamic with respect to plug type
 * - on demand content plugs can start from xx min
 * - live content plugs
 */

// type PlugType =
//   | "channel"
//   | "series"
//   | "episode"
//   | "standaloneProgram"
//   | "podcast"
//   | "podcastEpisode"
//   | "podcastSeason"
//   | "link"
//   | "page";

// const ActionBar: Component<{ plugType: PlugType; live: boolean }> = (props) => (
//   <div>
//     <Switch>
//       <Match when={props.plugType === "episode"}></Match>
//       <Match when={props.plugType === "channel"}></Match>
//     </Switch>
//   </div>
// );
